:root {
  --font-family-sans: "Main", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  --font-family-heading: "Main", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;

  --max-width: 1200px;
  --max-width-text: 760px;
  --max-width-text-content: 760px;
  --max-width-content: 760px;
  --max-width-product: 960px;


  --color-white: #FFFFFF;
  --color-black: #000000;

  /* Primary */
  /* https://colorbox.io/?c0=%26p%24s%24%3D3%26p%24h%24st%24%3D26%26p%24h%24e%24%3D44%26p%24h%24c%24%3Deqo%26p%24sa%24st%24%3D0.03%26p%24sa%24e%24%3D0.27%26p%24sa%24r%24%3D1%26p%24sa%24c%24%3Deqo%26p%24b%24st%24%3D0.97%26p%24b%24e%24%3D0.99%26p%24b%24c%24%3Deqti%26o%24n%24%3DBlue%26o%24ms%24%3D%5B%5D%26o%24ro%24%3Dcw&c1=%26p%24s%24%3D3%26p%24h%24st%24%3D44%26p%24h%24e%24%3D42%26p%24h%24c%24%3Deqo%26p%24sa%24st%24%3D0.27%26p%24sa%24e%24%3D0.74%26p%24sa%24r%24%3D1%26p%24sa%24c%24%3Deqo%26p%24b%24st%24%3D0.99%26p%24b%24e%24%3D0.99%26p%24b%24c%24%3Deqti%26o%24n%24%3DBlue+Copy%26o%24ms%24%3D%5B%5D%26o%24ro%24%3Dccw&c2=%26p%24s%24%3D6%26p%24h%24st%24%3D42%26p%24h%24e%24%3D28%26p%24h%24c%24%3Deqo%26p%24sa%24st%24%3D0.74%26p%24sa%24e%24%3D0.84%26p%24sa%24r%24%3D1%26p%24sa%24c%24%3Deqo%26p%24b%24st%24%3D0.99%26p%24b%24e%24%3D0.44%26p%24b%24c%24%3Deqti%26o%24n%24%3DBlue+Copy+Copy%26o%24ms%24%3D%5B%5D%26o%24ro%24%3Dccw */
  --color-primary-50: #F8F4F1;
  --color-primary-100: #f8e6c2;
  --color-primary-200: #fceab8;
  --color-primary-300: #fcce5d;
  --color-primary-400: #fcc441;
  --color-primary-500: #fbb038;
  --color-primary-600: #f79c30;
  --color-primary-700: #ed8c2a;
  --color-primary-800: #c46e20;
  --color-primary-900: #703e12;

  /* Neutral: Tailwind Slate */
  --color-neutral-50: #f8fafc;
  --color-neutral-100: #f1f5f9;
  --color-neutral-200: #e2e8f0;
  --color-neutral-300: #cbd5e1;
  --color-neutral-400: #94a3b8;
  --color-neutral-500: #64748b;
  --color-neutral-600: #475569;
  --color-neutral-700: #334155;
  --color-neutral-800: #1e293b;
  --color-neutral-900: #0f172a;

  /* Green */
  /* https://colorbox.io/?c0=%26p%24s%24%3D10%26p%24h%24st%24%3D152%26p%24h%24e%24%3D164%26p%24h%24c%24%3Deqo%26p%24sa%24st%24%3D0.05%26p%24sa%24e%24%3D0.92%26p%24sa%24r%24%3D1%26p%24sa%24c%24%3Deqo%26p%24b%24st%24%3D0.95%26p%24b%24e%24%3D0.31%26p%24b%24c%24%3Dl%26o%24n%24%3DGreen%26o%24ro%24%3Dcw%26o%24ms%24%3D%5B%5D%26o%24minorStep%24%3D1&c1=%26p%24s%24%3D10%26p%24h%24st%24%3D4%26p%24h%24e%24%3D0%26p%24h%24c%24%3Deqi%26p%24sa%24st%24%3D0.06%26p%24sa%24e%24%3D0.77%26p%24sa%24r%24%3D1.1%26p%24sa%24c%24%3Deqo%26p%24b%24st%24%3D0.98%26p%24b%24e%24%3D0.5%26p%24b%24c%24%3Dl%26o%24n%24%3DRed%26o%24ro%24%3Dccw%26o%24ms%24%3D%5B%5D%26o%24minorStep%24%3D1 */
  --color-green: #0f7357;
  --color-green-50: #e6f2ed;
  --color-green-100: #ace0ca;
  --color-green-200: #7bceae;
  --color-green-300: #54bc97;
  --color-green-400: #38aa85;
  --color-green-500: #249875;
  --color-green-600: #178566;
  --color-green-700: #0f7357;
  --color-green-800: #096149;
  --color-green-900: #064f3c;


  /* Red */
  --color-red: #b91c1c;
  --color-red-50: #faebe9;
  --color-red-100: #ecbab6;
  --color-red-200: #df8e89;
  --color-red-300: #d16b65;
  --color-red-400: #c35049;
  --color-red-500: #b63b36;
  --color-red-600: #a82c28;
  --color-red-700: #9b211e;
  --color-red-800: #8d1918;
  --color-red-900: #801414;

  /* Yellow */
  --color-yellow: #fcc441;
  --color-yellow-50: #F8F4F1;
  --color-yellow-100: #f8e6c2;
  --color-yellow-200: #fceab8;
  --color-yellow-300: #fcce5d;
  --color-yellow-400: #fcc441;
  --color-yellow-500: #fbb038;
  --color-yellow-600: #f79c30;
  --color-yellow-700: #ed8c2a;
  --color-yellow-800: #c46e20;
  --color-yellow-900: #703e12;

  --color-spinner: var(--color-neutral-300);
  --color-text-link: var(--color-primary-200);
}


/* Fonts */
@font-face {
  font-family: 'Main';
  src: url('/assets/fonts/gilroy-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Main';
  src: url('/assets/fonts/gilroy-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Main';
  src: url('/assets/fonts/gilroy-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@tailwind base;


/*
 Buttons
 */


.btn {
  @apply cursor-pointer text-center inline-flex items-center bg-white border px-3 py-2 border border-transparent rounded-lg font-medium leading-5 transition ease-in-out duration-150;
}

.btn:focus {
  @apply outline-none;
}

.btn:disabled {
  @apply cursor-default pointer-events-none;
}

/* Large */
.btn-large {
  @apply text-[16px] lg:text-[18px] leading-[26px] lg:leading-[30px];
}

/* Large */
.btn-xl {
  @apply text-[22px] lg:text-[24px] leading-[32px] lg:leading-[36px];
}

/* Small */
.btn-small {
  @apply py-1 px-2;
}

.btn-narrow{
  @apply px-2;
}

/* Extra-Small */
.btn-xs {
  @apply py-px px-1 text-sm;
}

/* Primary */
.btn-primary {
  @apply bg-primary-300 text-neutral-900;
}

.btn-primary:hover {
  @apply shadow-md;
}

.btn-primary:active {
  @apply bg-primary-200;
}

.btn-primary:disabled {
  @apply bg-primary-200 text-neutral-100;
}

/* Neutral */
.btn-neutral {
  @apply border-neutral-300 text-neutral-600;
}

.btn-neutral:hover {
  @apply bg-neutral-50;
}

.btn-neutral:active {
  @apply bg-neutral-100;
}

.btn-neutral:disabled {
  @apply text-neutral-300;
}

/* Neutral Dark */
.btn-neutral-dark {
  @apply bg-neutral-700 text-white;
}

.btn-neutral-dark:hover {
  @apply shadow-md;
}

.btn-neutral-dark:active {
  @apply bg-neutral-800;
}

.btn-neutral-dark:disabled {
  @apply bg-neutral-500 text-neutral-100;
}


/*
Forms
 */

.input-text {
  @apply w-full border text-neutral-600 border-neutral-300 rounded-lg pl-2 text-sm h-9 ring-0 focus:ring-0 focus:border-neutral-300 text-[15px] font-medium;
}

.input-text::placeholder {
  @apply font-normal text-neutral-400
}

.input-text-lg {
  @apply px-3 h-11 text-[18px];
}

.input-text-xl {
  @apply px-4 h-16 text-[20px];
}

.input-select {
  @apply w-full border border-neutral-300 rounded-lg h-9;
}

.input-select .selector button {
  @apply px-2 rounded-lg;
}


/*
 Slider
 */

slider input[type="range"]::-webkit-slider-thumb {
  width: 12px;
  height: 12px;
  -webkit-appearance: none;
  appearance: none;
  cursor: ew-resize;
  background: var(--color-neutral-600);
  box-shadow: -405px 0 0 400px var(--color-primary-300);
  border-radius: 50%;
}

slider input[type=range]::-moz-range-thumb {
  width: 12px;
  height: 12px;
  -webkit-appearance: none;
  appearance: none;
  cursor: ew-resize;
  background: var(--color-neutral-600);
  box-shadow: -405px 0 0 400px var(--color-primary-300);
  border-radius: 50%;
  border: 0;
}


@tailwind components;
@tailwind utilities;


/*
Site
 */

*, html {
  scroll-behavior: smooth !important;
}

body {
  @apply text-neutral-900;
}

.max-w-container {
  max-width: min(1200px, calc(100vw - 24px));
}

@screen sm {
  .max-w-container {
    max-width: min(1200px, calc(100vw - 48px));
  }
}

.max-w-container-sm {
  max-width: min(1100px, calc(100vw - 24px));
}

@screen sm {
  .max-w-container-sm {
    max-width: min(1100px, calc(100vw - 48px));
  }
}

.card {
  @apply border border-neutral-200 bg-white rounded-lg;
}


/*
Menu
 */

@layer utilities {
  .menu-link-active {
    box-shadow: inset 0 -6px 0 #FCEAB8;
    line-height: 22px;
    transition: box-shadow .5s ease;
  }

  .menu-link-active:hover {
    box-shadow: inset 0 -22px 0 #FCEAB8;
  }

  .menu-link:hover {
    box-shadow: inset 0 -22px 0 #FCEAB8;
    line-height: 22px;
    transition: box-shadow .5s ease;
  }
}

.vertical-scroll { /* Firefox */
  /*scrollbar-color: var(--color-neutral-300) var(--color-neutral-100) !important; */
}

.vertical-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  @apply w-[6px];
}

.vertical-scroll::-webkit-scrollbar-track {
  @apply bg-neutral-100
}

/* Handle */
.vertical-scroll::-webkit-scrollbar-thumb {
  @apply bg-neutral-300
}

/* Handle on hover */
.vertical-scroll::-webkit-scrollbar-thumb:hover {
  @apply bg-neutral-400;
}


.horizontal-scroll::-webkit-scrollbar {
  @apply h-[6px];
}

.horizontal-scroll::-webkit-scrollbar-track {
  @apply bg-neutral-100
}

/* Handle */
.horizontal-scroll::-webkit-scrollbar-thumb {
  @apply bg-neutral-300;
}

/* Handle on hover */
.horizontal-scroll::-webkit-scrollbar-thumb:hover {
  @apply bg-neutral-400;
}


/*
Text
 */

b, strong {
  @apply font-bold;
}

.text-title {
  @apply text-[40px] lg:text-[52px] leading-[44px] lg:leading-[58px] font-bold break-words;
}

.text-body {
  @apply text-[18px] lg:text-[20px] leading-[29px] lg:leading-[33px] break-words
}

.text-h1 {
  @apply text-[32px] lg:text-[40px] leading-[38px] lg:leading-[48px] font-bold break-words
}

.text-h2 {
  @apply text-[26px] lg:text-[34px] leading-[32px] lg:leading-[40px] font-bold break-words
}

.text-h3 {
  @apply text-[22px] lg:text-[30px] leading-[28px] lg:leading-[34px] font-bold break-words
}

.text-h4 {
  @apply text-[18px] lg:text-[24px] leading-[22px] lg:leading-[28px] font-bold break-words
}

.text-tool {
  @apply text-[15px] leading-[22px] lg:text-[17px] lg:leading-[26px]
}

.text-tool-sm {
  @apply text-[14px] leading-[21px] lg:text-[15px] lg:leading-[23px]
}

.text-tool-xs {
  @apply text-[12px] leading-[18px] lg:text-[13px] lg:leading-[20px]
}


.mb-text {
  @apply mb-[14px] lg:mb-[18px];
}

.mb-block-minus-text {
  @apply mb-[14px] lg:mb-[16px]
}

.mt-text {
  @apply mt-[14px] lg:mt-[18px];
}

.mb-block {
  @apply mb-[28px] lg:mb-[32px]
}

.mb-section {
  @apply mb-[34px] lg:mb-[40px]
}


.mt-h1 {
  @apply mt-[20px] lg:mt-[24px]
}

.mt-h2 {
  @apply mt-[8px] lg:mt-[12px]
}

.mt-h3 {
  @apply mt-[6px] lg:mt-[10px]
}

strong a {
  @apply !font-bold;
}

/*
Lists
 */

.bg-neutral-100 .ul-inner {
  @apply bg-neutral-400;
}

.bg-neutral-100 .ul-outer {
  @apply bg-neutral-200;
}

.bg-neutral-100 .ol-number {
  @apply text-neutral-500;
}

.bg-green-50 .ul-inner {
  @apply bg-green-500;
}

.bg-green-50 .ul-outer {
  @apply bg-green-100;
}

.bg-green-50 .ol-number {
  @apply text-green-500;
}

.bg-red-50 .ul-inner {
  @apply bg-red-500;
}

.bg-red-50 .ul-outer {
  @apply bg-red-100;
}

.bg-red-50 .ol-number {
  @apply text-red-500;
}


/*
Text Link
 */

.text-link {
  @apply font-medium cursor-pointer;
  box-shadow: inset 0 -4px 0 var(--color-text-link);
  transition: box-shadow .5s ease;
}

.text-link:hover {
  box-shadow: inset 0 -22px 0 var(--color-text-link);
}

.text-link-hover:hover {
  box-shadow: inset 0 -22px 0 var(--color-text-link);
  transition: box-shadow .5s ease;
}

.text-link-neutral {
  --color-text-link: var(--color-neutral-300);
}

.bg-green-50 {
  --color-text-link: var(--color-green-100);
}

.bg-red-50 {
  --color-text-link: var(--color-red-100);
}

.text-link:has(strong) {
  --color-text-link: var(--color-neutral-300);
}

.bg-neutral-50 {
  --color-text-link: var(--color-neutral-200);
}

.bg-neutral-100 {
  --color-text-link: var(--color-neutral-300);
}

.bg-primary-50 {
  --color-text-link: var(--color-primary-100);
}

.text-link-dotted {
  @apply underline decoration-dotted cursor-pointer hover:decoration-solid underline-offset-2;
}

/* https://fontawesome.com/icons/up-right-from-square?f=classic&s=solid + https://www.svgbackgrounds.com/tools/svg-to-css/ */
.external-symbol {
  height: 0.7em;
  aspect-ratio: 1;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill = '%2364748b' d='M352 0c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9L370.7 96 201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L416 141.3l41.4 41.4c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V32c0-17.7-14.3-32-32-32H352zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z'/%3E%3C/svg%3E");
}

/*
 * Truncate Hover
 */

.truncate-hover {
  @apply truncate;
}

.truncate-hover:hover {
  overflow: visible;
  background: white;
  z-index: 99999;
}

/*
Table
 */

.table-container {
  @apply max-w-full overflow-auto flex-none horizontal-scroll;
}

.table-container table {
  @apply w-full text-[18px] lg:text-[20px] leading-[28px] lg:leading-[32px];
}


.table-container table th {
  @apply bg-neutral-100 font-bold py-[14px] px-[20px] text-left;
}

.table-container table th:first-of-type {
  @apply rounded-tl-xl;
}

.table-container table th:last-of-type {
  @apply rounded-tr-xl;
}

.table-flat table th:first-of-type {
  @apply rounded-none;
}

.table-flat table th:last-of-type {
  @apply rounded-none;
}

.table-container table tbody tr {
  @apply border-b border-neutral-100;
}

.table-container table tbody td {
  @apply py-[25px] px-[20px] align-top;
}

.table-container table tbody tr:last-of-type {
  @apply border-0;
}

.table-container table tbody tr:last-of-type td {
  /* @apply pb-0; */
}

.table-content {
  @apply lg:max-w-content lg:min-w-text
}

.table-small table {
  @apply text-[15px] leading-[22px] lg:text-[17px] lg:leading-[26px]
}

.table-small table th {
  @apply py-[10px] px-[16px]
}

.table-small table tbody td {
  @apply py-[16px] px-[14px]
}

.table-xs table {
  @apply text-[14px] leading-[21px] lg:text-[15px] lg:leading-[23px]
}

.table-xs table th {
  @apply py-[4px] px-[8px]
}

.table-xs table tbody td {
  @apply py-[4px] px-[8px]
}

.table-align-middle table tbody td {
  @apply align-middle
}

.table-left table tbody td:first-of-type {
  @apply pl-0;
}

.table-matrix table tbody td{
  @apply border-r border-neutral-50
}

/**
Chart CSS
 */

.charts-css .tooltip {
  display: none;
}

.charts-css td:hover .tooltip {
  display: block
}

/**
 * Spinner
 */
.dot-carousel {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--color-spinner);
  color: var(--color-spinner);
  box-shadow: 9984px 0 0 0 var(--color-spinner), 9999px 0 0 0 var(--color-spinner), 10014px 0 0 0 var(--color-spinner);
  animation: dotCarousel 1.5s infinite linear;
}

@keyframes dotCarousel {
  0% {
    box-shadow: 9984px 0 0 -1px var(--color-spinner), 9999px 0 0 1px var(--color-spinner), 10014px 0 0 -1px var(--color-spinner);
  }
  50% {
    box-shadow: 10014px 0 0 -1px var(--color-spinner), 9984px 0 0 -1px var(--color-spinner), 9999px 0 0 1px var(--color-spinner);
  }
  100% {
    box-shadow: 9999px 0 0 1px var(--color-spinner), 10014px 0 0 -1px var(--color-spinner), 9984px 0 0 -1px var(--color-spinner);
  }
}

.spinner {
  color: transparent !important;
  pointer-events: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner::after {
  content: '';
  top: calc(50% - (2em / 2));
  left: calc(50% - (2em / 2));
  display: block;
  width: 2em;
  height: 2em;
  border: 4px solid var(--color-spinner);
  border-radius: 9999px;
  border-right-color: transparent;
  border-top-color: transparent;
  animation: spin 500ms linear infinite;
}


/**
Lite YouTube
 */

.lite-youtube {
  background-color: #000;
  position: relative;
  display: block;
  contain: content;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

/* play button */
.lite-youtube > .lty-playbtn {
  display: block;
  width: 68px;
  height: 48px;
  position: absolute;
  cursor: pointer;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  z-index: 1;
  background-color: transparent;
  /* YT's actual play button svg */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 48"><path d="M66.52 7.74c-.78-2.93-2.49-5.41-5.42-6.19C55.79.13 34 0 34 0S12.21.13 6.9 1.55c-2.93.78-4.63 3.26-5.42 6.19C.06 13.05 0 24 0 24s.06 10.95 1.48 16.26c.78 2.93 2.49 5.41 5.42 6.19C12.21 47.87 34 48 34 48s21.79-.13 27.1-1.55c2.93-.78 4.64-3.26 5.42-6.19C67.94 34.95 68 24 68 24s-.06-10.95-1.48-16.26z" fill="red"/><path d="M45 24 27 14v20" fill="white"/></svg>');
  /*filter: grayscale(100%);
  transition: filter .1s cubic-bezier(0, 0, 0.2, 1); */
  border: none;
}

.lite-youtube:hover > .lty-playbtn,
.lite-youtube .lty-playbtn:focus {
  filter: none;
}

/* Post-click styles */
.lite-youtube.lyt-activated {
  cursor: unset;
}

.lite-youtube.lyt-activated::before,
.lite-youtube.lyt-activated > .lty-playbtn {
  opacity: 0;
  pointer-events: none;
}

.lyt-visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/*
Ads
 */

.skyscraper {
  @apply w-[300px] h-full top-0 absolute hidden;
}

.skyscraper-right {
  @apply right-0
}


@media (min-width: 1500px) {
  .skyscraper {
    @apply block
  }

  .skyscraper-left {
    margin-left: max(-300px, calc((1210px - 100vw) / 2))
  }

  .skyscraper-right {
    margin-right: max(-300px, calc((1230px - 100vw) / 2))
  }
}


